body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* tailwind.css or global.css */
@layer base {
  h1 {
    @apply text-3xl font-bold my-4;
  }
  h2 {
    @apply text-2xl font-semibold my-3;
  }
  h3 {
    @apply text-xl font-medium my-2;
  }
  h4 {
    @apply text-lg font-medium my-1;
  }
  h5 {
    @apply text-base font-medium;
  }
  h6 {
    @apply text-sm font-medium;
  }

  ul {
    @apply list-disc pl-6 my-2; /* Adds disc bullet style and padding-left */
  }
  
  /* Styling for ordered lists */
  ol {
    @apply list-decimal pl-6 my-2; /* Adds decimal style and padding-left */
  }

  /* Styling for list items */
 
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #555555;
}

::-webkit-scrollbar-thumb {
  background: #767676;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #767676;
}

/* ReactQuill Editor Styles */
.ql-toolbar.ql-snow {
  border: 1px solid #3f3f46 !important; /* zinc-700 */
  background-color: #27272a !important; /* zinc-800 */
  border-radius: 4px;
}

.ql-container.ql-snow {
  border: 1px solid #3f3f46 !important; /* zinc-700 */
  border-radius: 4px;
}

.ql-editor {
  background-color: #18181b !important; /* zinc-900 */
  color: #ffffff !important;
  border-radius: 4px;
}

.ql-picker {
  color: #ffffff !important;
}

.ql-stroke {
  stroke: #ffffff !important;
}

.ql-fill {
  fill: #ffffff !important;
}

.ql-picker-options {
  background-color: #27272a !important; /* zinc-800 */
  border-color: #3f3f46 !important; /* zinc-700 */
}

.ql-picker-item {
  color: #ffffff !important;
}

.ql-picker-item:hover {
  background-color: #3f3f46 !important; /* zinc-700 */
}

.ql-tooltip {
  background-color: #27272a !important; /* zinc-800 */
  color: #ffffff !important;
  border-color: #3f3f46 !important; /* zinc-700 */
}

.ql-tooltip input[type=text] {
  background-color: #18181b !important; /* zinc-900 */
  color: #ffffff !important;
  border-color: #3f3f46 !important; /* zinc-700 */
}

/* Keep your existing styles for images */
.ql-editor img {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
}

body {
  @apply bg-background text-white; /* Setting background color and default text color */
}

.toolbar-btn {
  @apply bg-zinc-700 hover:bg-zinc-600 text-white w-10 h-10 rounded-md flex items-center justify-center transition-colors duration-200;
}